import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// GLOBAL CUSTOM COMPONENTS

import {H3} from "@/components/Typography";
import {Carousel} from "@/components/carousel";
import BazaarImage from "@/components/BazaarImage";
import FlexRowCenter from "@/components/flex-box/flex-row-center";
import {router} from "@inertiajs/react";
// CUSTOM DATA MODEL


// ==========================================================
export default function Shops({
                                  shops
                              }) {
    const responsive = [{
        breakpoint: 1024,
        settings: {
            slidesToShow: 4
        }
    }, {
        breakpoint: 800,
        settings: {
            slidesToShow: 3
        }
    }, {
        breakpoint: 650,
        settings: {
            slidesToShow: 2
        }
    }];
    return <Container className="mt-4 mb-4">
        <H3 mb={3}>Featured Shops</H3>

        <Box padding={4} bgcolor="white" sx={{
            ".slick-slide": {
                textAlign: "center",
            }
        }}>
            <Carousel slidesToShow={5} arrows={false} responsive={responsive}>
                {shops.map(({
                                id,
                                logo,
                                slug,
                            }) => <FlexRowCenter
                    onClick={() => {
                        router.visit(`/shops/${slug}`);
                    }}
                    maxWidth={110} height="100%" margin="auto" sx={{cursor: 'pointer'}}
                    key={id}>
                    <BazaarImage alt="brand" width="100%" src={`/storage/${logo}`} sx={{
                        filter: "grayscale(1)",
                    }}/>
                </FlexRowCenter>)}
            </Carousel>
        </Box>
    </Container>;
}
